const generatePaginationElements = (current: number, total: number) => {
  // Offset until an ellipsis replace the page numbers
  const ellipsisOffset = 4;

  if (total === 1) {
    return [1];
  }

  const left = [...Array(ellipsisOffset).keys()]
    .reverse()
    .map((iter) => current - (iter + 1));
  const right = [...Array(ellipsisOffset).keys()].map(
    (iter) => current + (iter + 1)
  );
  const center = [...left, current, ...right],
    filteredCenter: (number | '...')[] = center.filter(
      (p) => p > 1 && p < total
    ),
    includeLeftDots = current > 2 + ellipsisOffset,
    includeRightDots = current < total - ellipsisOffset - 1;

  if (includeLeftDots) filteredCenter.unshift('...');
  if (includeRightDots) filteredCenter.push('...');

  return [1, ...filteredCenter, total];
};

export default generatePaginationElements;
