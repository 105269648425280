import * as userApi from '../api/user';

export interface User {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  token?: string;
}

export const login = ({ email, password }: User) => {
  return userApi.login({ email, password }).then(
    ({ user }) => {
      alert('Successful login' + user.email);
    },
    (error) => {
      console.log('error', error);
    }
  );
};

export const register = ({ first_name, last_name, email, password }: User) => {
  return userApi.register({ first_name, last_name, email, password }).then(
    ({ user }) => {
      alert(user.email + ' added to users');
    },
    (error) => {
      console.log('error', error);
    }
  );
};

// const setAuthenticated = (userData: User) => {
//   currentUser.updateAuthenticated(userData);
//   setAuthToken(userData.token);
//   localStorage.setItem('token', userData.token);
// };

// const clearExistingToken = () => {
//   removeAuthToken();
//   localStorage.removeItem('token');
// };
