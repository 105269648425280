import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-big-calendar';

import Signin from './pages/Signin';
import Simulation from './pages/Simulation';
import ThreadEstimate from './pages/ThreadEstimate';
import HistoricalView from './pages/HistoricalView';
import ClientThreads from './pages/ClientThreads';
import { SessionProvider } from './contexts/Session';
//import GoogleBtn from './components/GoogleBtn';

const App = (): JSX.Element => {
  return (
    <SessionProvider>
      <div className="App">
        <Switch>
          <Route path="/simulation" exact component={Simulation} />
          <Route path="/history" exact component={HistoricalView} />
          <Route
            path="/simulation/:threadId"
            exact
            component={ThreadEstimate}
          />
          <Route path="/:email" exact component={ClientThreads} />
          <Route path="/" exact component={Signin} />
          {/* <Route path="/google" exact component={GoogleBtn} /> */}
          {/* <Route path="/sample" render={routerProps => <Sample {...routerProps} sampleProp={"sample"}/>} />
        <Route path="/something" component={Something} />
        <Route path='/default' render={() => <Redirect to= "/" />} />
        <Route component={NoMatch} /> */}
        </Switch>
      </div>
    </SessionProvider>
  );
};

export default App;
