const theme = {
  font: {
    poppins: 'Poppins',
    archivo: 'Archivo',
  },
  fontSize: {
    xs: '10px',
    sm: '12px',
    p: '14px',
    med: '16px',
    header: '40px',
    subheader: '30px',
  },
  color: {
    black: 'black',
    aqua: 'aqua',
    darksalmon: 'darksalmon',
    yellow: 'lightyellow',
    green: 'lightgreen',
    teal: '#2ca6a4',
    brightyellow: '#ffeb3be0',
    indigo: '#0d3b66',
    lemon: '#faf0ca',
    orangeyellow: '#f4d35e',
    sandybrown: '#ee964b',
    orangesoda: '#f95738',
    whitesmoke: 'whitesmoke',
    indianred: '#c95d63',
    grey: '#868686',
  },
};

export default theme;
