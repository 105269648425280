import React from 'react';
import styled from 'styled-components';

const StyledFont = styled.text`
  float: left;
  text-align: left;
  font-size: ${(props) => props.theme.fontSize.sm};
  font-family: ${(props) => props.theme.font.archivo};
`;

const FontStyle: React.FC = ({ children }) => {
  return (
    <>
      <StyledFont>{children}</StyledFont>
    </>
  );
};

export default FontStyle;
