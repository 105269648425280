import React, { useEffect } from 'react';
import { User } from '../api/types';

export type SetCurrentUser = React.Dispatch<React.SetStateAction<User | null>>;

type ContextType = {
  currentUser: User | null;
  setCurrentUser: SetCurrentUser;
  isLoading: boolean;
};

type ProviderProps = {
  children: React.ReactNode;
};

const SessionContext = React.createContext<ContextType | undefined>(undefined);

const useSession = () => {
  const context = React.useContext(SessionContext);
  if (!context) {
    throw new Error(`useSession must be used within an SessionContext`);
  }
  return context;
};

const SessionProvider = ({ children }: ProviderProps) => {
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);
  // const [isLoading, setIsLoading] = React.useState(true);
  const [isLoading] = React.useState(true);

  useEffect(() => {
    const localUser = localStorage.getItem('currentUser');
    if (localUser) {
      setCurrentUser((JSON.parse(localUser) as unknown) as User);
    }
  }, []);

  const value: ContextType = {
    currentUser,
    setCurrentUser,
    isLoading,
  };
  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export { SessionProvider, useSession };
