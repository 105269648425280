// TODO: Refactor this. Remove the eslint-disable

export const toJson = (response: any) => response.json();

export const getResponseErrors = (response: any) => {
  return response.json().then((errors: any) => {
    return Promise.reject(
      Object.entries(errors).map(([key, value]) => `${key} ${value}`)
    );
  });
};
