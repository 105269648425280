import React from 'react';
import { Table, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Email from './Email';
import {
  ThreadID,
  Initiator,
  Round,
  SubHeader,
  Responder,
  Round2,
  Round3,
  Label,
} from './styles';
import { abbrTimezone, prepareDayjs } from '../../utils';
import { Thread as TThread } from '../../api/types';

interface ThreadProps {
  item: TThread;
}

const Thread = ({ item }: ThreadProps) => {
  const dayjs = prepareDayjs();
  const history = useHistory();
  const timeZone = dayjs().format('zzz');
  const abbr = abbrTimezone(timeZone);
  const { emails } = item;
  const { initiator } = item;
  const { responder } = item;

  return (
    <>
      <tr>
        <td colSpan={2}>
          <tr>
            <Button
              variant="dark"
              onClick={() => history.push(`/simulation/${item.id}`)}
            >
              ID: {item.id}
            </Button>
          </tr>
          <tr>
            <ThreadID>thread type: {item.kind}</ThreadID>
          </tr>
          <tr>
            <ThreadID>timezone overlap: {item.timezone_overlap}</ThreadID>
          </tr>
          <tr>
            <ThreadID>template id: {item.template_id}</ThreadID>
          </tr>
        </td>
        <td>
          <Table size="sm">
            <tbody>
              <Initiator>
                <tr>
                  <Round>INITIATOR</Round>
                </tr>
                <tr>
                  <SubHeader>{initiator.email}</SubHeader>
                </tr>
                <tr>
                  <Label>
                    START TIME:
                    {dayjs(initiator.start_time).format(
                      'MMM DD, YYYY h:mm:ss a '
                    ) + abbr}
                  </Label>
                </tr>
                <tr>
                  <Label>
                    END TIME:
                    {dayjs(initiator.end_time).format(
                      'MMM DD, YYYY h:mm:ss a '
                    ) + abbr}
                  </Label>
                </tr>
                <tr>
                  <Label>{initiator.active ? 'active' : 'inactive'}</Label>
                </tr>
                <tr>
                  <Label>{initiator.nbod ? 'NBOD' : 'BOD'}</Label>
                </tr>
                <tr>
                  <Label>
                    {initiator.within_work_hours
                      ? 'within work hours'
                      : 'NOT within work hours'}
                  </Label>
                </tr>
                <tr>
                  <Label>{initiator.in_use ? 'used' : 'unused'}</Label>
                </tr>
                <tr>
                  <Label>ESTIMATE: {initiator.estimate.toFixed(2)}</Label>
                </tr>
                <tr>
                  <Label>SENT TODAY: {initiator.sent_today}</Label>
                </tr>
              </Initiator>
            </tbody>
          </Table>
        </td>
        <td>
          <Table size="sm">
            <tbody>
              <Responder>
                <tr>
                  <Round>RESPONDER</Round>
                </tr>
                <tr>
                  <SubHeader>{responder.email}</SubHeader>
                </tr>
                <tr>
                  <Label>
                    START TIME:
                    {dayjs(responder.start_time).format(
                      'MMM DD, YYYY h:mm:ss a '
                    ) + abbr}
                  </Label>
                </tr>
                <tr>
                  <Label>
                    END TIME:
                    {dayjs(responder.end_time).format(
                      'MMM DD, YYYY h:mm:ss a '
                    ) + abbr}
                  </Label>
                </tr>
                <tr>
                  <Label>{responder.active ? 'active' : 'inactive'}</Label>
                </tr>
                <tr>
                  <Label>{responder.nbod ? 'NBOD' : 'BOD'}</Label>
                </tr>
                <tr>
                  <Label>
                    {responder.within_work_hours
                      ? 'within work hours'
                      : 'NOT within work hours'}
                  </Label>
                </tr>
                <tr>
                  <Label>{responder.in_use ? 'used' : 'unused'}</Label>
                </tr>
                <tr>
                  <Label>ESTIMATE: {responder.estimate.toFixed(2)}</Label>
                </tr>
                <tr>
                  <Label>SENT TODAY: {responder.sent_today}</Label>
                </tr>
              </Responder>
            </tbody>
          </Table>
        </td>
        {/* <ThreadID>{item.id}</ThreadID> */}
        <Col>
          {emails.map((email: any) => (
            <Email key={email.item?.id} item={email} round={1} />
          ))}
        </Col>
        <Round2>
          {emails.map((email: any) => (
            <Email key={email.item?.id} item={email} round={2} />
          ))}
        </Round2>
        <Round3>
          {emails.map((email: any) => (
            <Email key={email.item?.id} item={email} round={3} />
          ))}
        </Round3>
        {emails.map((email: any) => (
          <Email key={email.item?.id} item={email} round={4} />
        ))}
      </tr>
    </>
  );
};

export default Thread;
