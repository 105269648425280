import React, { useState, useEffect } from 'react';
import Client from '../components/Estimate/Client';
import { Table, Col, Card, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';
import wretch from 'wretch';
import 'react-datetime/css/react-datetime.css';
import { abbrTimezone, prepareDayjs } from '../utils';
const ThreadEstimate = (props: any) => {
  const Label = styled.div`
    font-size: ${(props) => props.theme.fontSize.sm};
    font-family: ${(props) => props.theme.font.poppins};
    color: ${(props) => props.theme.color.whitesmoke};
    text-align: left;
  `;
  const SubHeader = styled.div`
    font-size: ${(props) => props.theme.fontSize.med};
    font-family: ${(props) => props.theme.font.poppins};
    color: ${(props) => props.theme.color.whitesmoke};
  `;
  const Initiator = styled.div`
    background-color: ${(props) => props.theme.color.indigo};
    padding: 5px;
  `;
  const Responder = styled.div`
    background-color: ${(props) => props.theme.color.indianred};
    padding: 5px;
  `;
  const Sender = styled.div`
    font-size: ${(props) => props.theme.fontSize.sm};
    font-family: ${(props) => props.theme.font.poppins};
    background-color: ${(props) => props.theme.color.orangeyellow};
    padding: 5px;
    // border-radius: 20px;
  `;
  const Recipient = styled.div`
    font-size: ${(props) => props.theme.fontSize.sm};
    font-family: ${(props) => props.theme.font.poppins};
    background-color: ${(props) => props.theme.color.sandybrown};
    padding: 5px;
    //border-radius: 20px;
  `;
  const Round = styled.div`
    font-size: ${(props) => props.theme.fontSize.sm};
    font-family: ${(props) => props.theme.font.poppins};
    background-color: ${(props) => props.theme.color.yellow};
  `;
  const Round2 = styled.td`
    background-color: ${(props) => props.theme.color.teal};
  `;
  const Round3 = styled.td`
    background-color: ${(props) => props.theme.color.lemon};
  `;
  const TableCell = styled.td`
    border: 0 !important;
  `;
  const ThreadID = styled.td`
    font-size: ${(props) => props.theme.fontSize.sm};
    font-family: ${(props) => props.theme.font.archivo};
    text-align: left;
  `;
  const dayjs = prepareDayjs();
  const timeZone = dayjs().format('zzz');
  const abbr = abbrTimezone(timeZone);
  const [estimates, setEstimates] = useState<any>([]);
  const { match } = props;
  const { params } = match;
  const { threadId } = params;
  function truncate(emailBody: any, words: any) {
    return emailBody.split(' ').splice(0, words).join(' ');
  }
  const renderEstimates = (estimates: any) => {
    const result = [];
    for (const item of estimates) {
      const { thread } = item;
      const { clients } = item;
      result.push(
        <tr>
          <td>
            {dayjs(item.created_at).format('MMM DD, YYYY h:mm:ss a ') + abbr}
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    CLIENTS
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {clients !== null || undefined ? (
                      clients.map((client: any) => (
                        <Client key={client.id} client={client} />
                      ))
                    ) : (
                      <> </>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            {/* {clients !== undefined ? renderClients(clients) : <> </>} */}
          </td>
          {renderThreads(thread)}
        </tr>
      );
    }
    return result;
  };
  const renderThreads = (threads: any) => {
    const result = [];
    for (const item of threads) {
      const { emails } = item;
      const { initiator } = item;
      const { responder } = item;
      result.push(
        <>
          <tr>
            <td colSpan={2}>
              <tr>
                <ThreadID>ID: {item.id}</ThreadID>
              </tr>
              <tr>
                <ThreadID>thread type: {item.kind}</ThreadID>
              </tr>
              <tr>
                <ThreadID>timezone overlap: {item.timezone_overlap}</ThreadID>
              </tr>
              <tr>
                <ThreadID>template id: {item.template_id}</ThreadID>
              </tr>
            </td>
            <td>
              <Table size="sm">
                <tbody>
                  <Initiator>
                    <tr>
                      <Round>INITIATOR</Round>
                    </tr>
                    <tr>
                      <SubHeader>{initiator.email}</SubHeader>
                    </tr>
                    <tr>
                      <Label>
                        START TIME:
                        {dayjs(initiator.start_time).format(
                          'MMM DD, YYYY h:mm:ss a '
                        ) + abbr}
                      </Label>
                    </tr>
                    <tr>
                      <Label>
                        END TIME:
                        {dayjs(initiator.end_time).format(
                          'MMM DD, YYYY h:mm:ss a '
                        ) + abbr}
                      </Label>
                    </tr>
                    <tr>
                      <Label>{initiator.active ? 'active' : 'inactive'}</Label>
                    </tr>
                    <tr>
                      <Label>{initiator.nbod ? 'NBOD' : 'BOD'}</Label>
                    </tr>
                    <tr>
                      <Label>
                        {initiator.within_work_hours
                          ? 'within work hours'
                          : 'NOT within work hours'}
                      </Label>
                    </tr>
                    <tr>
                      <Label>{initiator.in_use ? 'used' : 'unused'}</Label>
                    </tr>
                    <tr>
                      <Label>ESTIMATE: {initiator.estimate.toFixed(2)}</Label>
                    </tr>
                    <tr>
                      <Label>SENT TODAY: {initiator.sent_today}</Label>
                    </tr>
                  </Initiator>
                </tbody>
              </Table>
            </td>
            <td>
              <Table size="sm">
                <tbody>
                  <Responder>
                    <tr>
                      <Round>RESPONDER</Round>
                    </tr>
                    <tr>
                      <SubHeader>{responder.email}</SubHeader>
                    </tr>
                    <tr>
                      <Label>
                        START TIME:
                        {dayjs(responder.start_time).format(
                          'MMM DD, YYYY h:mm:ss a '
                        ) + abbr}
                      </Label>
                    </tr>
                    <tr>
                      <Label>
                        END TIME:
                        {dayjs(responder.end_time).format(
                          'MMM DD, YYYY h:mm:ss a '
                        ) + abbr}
                      </Label>
                    </tr>
                    <tr>
                      <Label>{responder.active ? 'active' : 'inactive'}</Label>
                    </tr>
                    <tr>
                      <Label>{responder.nbod ? 'NBOD' : 'BOD'}</Label>
                    </tr>
                    <tr>
                      <Label>
                        {responder.within_work_hours
                          ? 'within work hours'
                          : 'NOT within work hours'}
                      </Label>
                    </tr>
                    <tr>
                      <Label>{responder.in_use ? 'used' : 'unused'}</Label>
                    </tr>
                    <tr>
                      <Label>ESTIMATE: {responder.estimate.toFixed(2)}</Label>
                    </tr>
                    <tr>
                      <Label>SENT TODAY: {responder.sent_today}</Label>
                    </tr>
                  </Responder>
                </tbody>
              </Table>
            </td>
            {/* <ThreadID>{item.id}</ThreadID> */}
            <Col>{renderEmails(emails, 1)}</Col>
            <Round2>{renderEmails(emails, 2)}</Round2>
            <Round3>{renderEmails(emails, 3)}</Round3>
            {renderEmails(emails, 4)}
          </tr>
        </>
      );
      break;
    }
    return result;
  };
  const renderEmails = (threads: any, round: any) => {
    const result = [];
    for (const item of threads) {
      const { recipient } = item;
      const { sender } = item;
      result.push(
        <>
          {item.round === round && (
            <TableCell id={round}>
              <Round>{item.round}</Round>
              {item.thread_number != null && (
                <Initiator>
                  <SubHeader>thread no. {item.thread_number}</SubHeader>
                </Initiator>
              )}
              <Sender>{sender.email}</Sender>
              <Recipient>{recipient.email}</Recipient>
              <span>{item.probability.toFixed(2)}</span>
              <Round>{item.state}</Round>
              <Accordion>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <ThreadID>{truncate(item.body, 7)}</ThreadID>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ThreadID>{item.body}</ThreadID>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Round>
                {item.scheduled_at == null
                  ? ''
                  : dayjs(item.scheduled_at).format('MMM DD, YYYY h:mm:ss a ') +
                    abbr}
              </Round>
              {item.id}
            </TableCell>
          )}
        </>
      );
    }
    return result;
  };
  useEffect(() => {
    wretch(
      'https://mailable-api.spstage.com/monitoring/estimates?thread_id=' +
        threadId
    )
      .get()
      .json((response) => {
        setEstimates(response);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ROUNDS</th>
          </tr>
        </thead>
        <tbody>{renderEstimates(estimates)}</tbody>
      </Table>
    </>
  );
};

export default ThreadEstimate;
