// TODO: Refactor this. Remove the eslint-disable

import React, { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { User } from '../interface/User';
import Label from '../components/Label';
import styled from 'styled-components';
const initialFormState = { email: '', password: '' };

interface Props {
  signIn: (userForm: User) => void;
}

const SigninForm: React.FC<Props> = ({ signIn }) => {
  const [userForm, setUser] = useState(initialFormState);

  const handleInputChange = (event: {
    target: { name: any; value: string };
  }) => {
    const { name, value } = event.target;

    setUser({ ...userForm, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!userForm.email || !userForm.password) return;

    signIn(userForm);
    setUser(initialFormState);
  };

  const StyleHeader = styled.text`
    font-size: ${(props) => props.theme.fontSize.subheader};
    font-family: ${(props) => props.theme.font.poppins};
  `;

  const Right = styled.a`
    text-align: right;
    float: right;
    font-size: ${(props) => props.theme.fontSize.sm};
    font-family: ${(props) => props.theme.font.archivo};
    text-decoration: underline;
    color: black;
  `;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <StyleHeader className="mb-5">
          <h2>Login</h2>
        </StyleHeader>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Label>
            <Form.Label>Email Address</Form.Label>
          </Label>
          <Form.Control
            type="email"
            onChange={handleInputChange}
            name="email"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className="mb-3">
        <Form.Group as={Col} controlId="formGridPassword">
          <Label>
            <Form.Label>Password</Form.Label>
          </Label>
          <Form.Control
            type="password"
            onChange={handleInputChange}
            name="password"
            required
          />
        </Form.Group>
      </Form.Row>

      <Button className="mb-4" variant="dark" type="submit" block>
        Sign In
      </Button>
      <Right>Forgot Password?</Right>
    </Form>
  );
};

export default SigninForm;
