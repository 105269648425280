import 'react-datetime/css/react-datetime.css';
import React, { useState, useEffect } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import wretch from 'wretch';
import ClientIndex from '../components/Estimate/ClientIndex';
import { ClientEstimateSnapshot } from '../api/types';

const ClientThreads = (props: any) => {
  const [estimates, setEstimates] = useState<ClientEstimateSnapshot[]>(
    ([] as unknown) as ClientEstimateSnapshot[]
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { match } = props;
    const { params } = match;
    const { email } = params;
    setLoading(true);
    wretch(`${API_URL}/monitoring/estimates?email=${email}`)
      .get()
      .json((response) => {
        setLoading(false);
        setEstimates(response as ClientEstimateSnapshot[]);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <span>Time</span>
            </th>
            <th>ROUNDS</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={2}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </td>
            </tr>
          )}
          {estimates.map((estimate: any) => (
            <ClientIndex key={estimate.id} estimate={estimate} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ClientThreads;
