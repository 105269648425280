import { get, post, put } from './http';
import { toJson, getResponseErrors } from './utils';
import { User } from '../interface/User';

export const getCurrentUser = () => {
  return get('user').then(toJson);
};

export const register = ({ first_name, last_name, email, password }: User) => {
  return post('register', {
    user: {
      first_name,
      last_name,
      email,
      password,
    },
  }).then(toJson, getResponseErrors);
};

export const login = ({ email, password }: User) => {
  return post('signin', {
    email,
    password,
  }).then(toJson, getResponseErrors);
};

export const update = ({ first_name, last_name, email, password }: User) => {
  return put('user', {
    user: {
      first_name,
      last_name,
      email,
      password,
    },
  }).catch(getResponseErrors);
};
