import dayjs from 'dayjs';
import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { abbrTimezone } from '../../utils';
import { useHistory } from 'react-router-dom';

import { Initiator, Label } from './styles';
import { Client as TClient } from '../../api/types';

interface ClientProps {
  client: TClient;
}

const Client = ({ client }: ClientProps) => {
  const timeZone = dayjs().format('zzz');
  const abbr = abbrTimezone(timeZone);
  const history = useHistory();

  return (
    <Table size="sm">
      <tbody>
        <Initiator>
          <tr>
            <Button
              variant="info"
              onClick={() => history.push(`/${client.email}`)}
            >
              {client.email}
            </Button>
          </tr>
          <tr>
            <Label>
              START TIME:
              {dayjs(client.start_time).format('MMM DD, YYYY h:mm:ss a ') +
                abbr}
            </Label>
          </tr>
          <tr>
            <Label>
              END TIME:
              {dayjs(client.end_time).format('MMM DD, YYYY h:mm:ss a ') + abbr}
            </Label>
          </tr>
          <tr>
            <Label>{client.active ? 'active' : 'inactive'}</Label>
          </tr>
          <tr>
            <Label>{client.nbod ? 'NBOD' : 'BOD'}</Label>
          </tr>
          <tr>
            <Label>
              {client.within_work_hours
                ? 'within work hours'
                : 'NOT within work hours'}
            </Label>
          </tr>
          <tr>
            <Label>{client.in_use ? 'used' : 'unused'}</Label>
          </tr>
          <tr>
            <Label>ESTIMATE: {client.estimate.toFixed(2)}</Label>
          </tr>
          <tr>
            <Label>SENT TODAY: {client.sent_today}</Label>
          </tr>
        </Initiator>
      </tbody>
    </Table>
  );
};

export default Client;
