import React, { useEffect, useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useSession } from '../contexts/Session';
import { login, register } from '../interface/User';
import RegistrationForm from './RegistrationForm';
import SigninForm from './SigninForm';
import Label from '../components/Label';
import styled from 'styled-components';

const Signin = () => {
  const [toLoggedIn, setToLoggedIn] = useState(true);
  const [user, setUser] = useState({});
  const { currentUser } = useSession();

  useEffect(() => {
    if (toLoggedIn) {
      login(user);
    } else {
      register(user);
    }
  }, [toLoggedIn, user]);

  console.log(currentUser);

  const StyledLink = styled.a`
    color: black;
    text-decoration: underline;
  `;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-9">
          <Container fluid="xl" className="mt-5">
            <Navbar
              expand="lg"
              variant="light"
              bg="white"
              className="justify-content-between"
            >
              <Navbar.Brand href="#">
                <img alt="icon" src="/mailable-icon.png" />
              </Navbar.Brand>
              {toLoggedIn ? (
                <Label>
                  Need an Account?
                  <StyledLink onClick={() => setToLoggedIn(false)}>
                    Create an account
                  </StyledLink>
                </Label>
              ) : (
                <Label>
                  Already have an account?
                  <StyledLink onClick={() => setToLoggedIn(true)}>
                    Login
                  </StyledLink>
                </Label>
              )}
            </Navbar>
            <div className="row">
              <div className="col-md-6 offset-md-3">
                {toLoggedIn ? (
                  <SigninForm signIn={setUser} />
                ) : (
                  <RegistrationForm signUp={setUser} />
                )}
              </div>
            </div>
          </Container>
        </div>
        <div
          className="col-3"
          style={{ backgroundColor: 'grey', height: '100vh' }}
        ></div>
      </div>
    </div>
  );
};

export default Signin;
