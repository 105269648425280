import styled from 'styled-components';

export const Label = styled.div`
  font-size: ${(props) => props.theme.fontSize.sm};
  font-family: ${(props) => props.theme.font.poppins};
  color: ${(props) => props.theme.color.whitesmoke};
  text-align: left;
`;
export const SubHeader = styled.div`
  font-size: ${(props) => props.theme.fontSize.med};
  font-family: ${(props) => props.theme.font.poppins};
  color: ${(props) => props.theme.color.whitesmoke};
`;
export const Initiator = styled.div`
  background-color: ${(props) => props.theme.color.indigo};
  padding: 5px;
`;
export const Responder = styled.div`
  background-color: ${(props) => props.theme.color.indianred};
  padding: 5px;
`;
export const Sender = styled.div`
  font-size: ${(props) => props.theme.fontSize.sm};
  font-family: ${(props) => props.theme.font.poppins};
  background-color: ${(props) => props.theme.color.orangeyellow};
  padding: 5px;
  // border-radius: 20px;
`;
export const Recipient = styled.div`
  font-size: ${(props) => props.theme.fontSize.sm};
  font-family: ${(props) => props.theme.font.poppins};
  background-color: ${(props) => props.theme.color.sandybrown};
  padding: 5px;
  //border-radius: 20px;
`;
export const Round = styled.div`
  font-size: ${(props) => props.theme.fontSize.sm};
  font-family: ${(props) => props.theme.font.poppins};
  background-color: ${(props) => props.theme.color.yellow};
`;
export const Round2 = styled.td`
  background-color: ${(props) => props.theme.color.teal};
`;
export const Round3 = styled.td`
  background-color: ${(props) => props.theme.color.lemon};
`;
export const TableCell = styled.td`
  border: 0 !important;
`;
export const ThreadID = styled.td`
  font-size: ${(props) => props.theme.fontSize.sm};
  font-family: ${(props) => props.theme.font.archivo};
  text-align: left;
`;
export const DateStyle = styled.div`
  font-size: ${(props) => props.theme.fontSize.sm};
  font-family: ${(props) => props.theme.font.archivo};
  color: ${(props) => props.theme.color.black};
  text-align: left;
`;
export const NumberStyle = styled.div`
  font-size: ${(props) => props.theme.fontSize.subheader};
  font-family: ${(props) => props.theme.font.poppins};
  color: ${(props) => props.theme.color.whitesmoke};
  background-color: ${(props) => props.theme.color.indigo};
`;
