import React, { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { User } from '../interface/User';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from '../components/Label';
const initialFormState = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
};

interface Props {
  signUp: (userForm: User) => void;
}

const RegistrationForm: React.FC<Props> = ({ signUp }) => {
  const [userForm, setUser] = useState(initialFormState);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setUser({ ...userForm, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    signUp(userForm);
    setUser(initialFormState);
  };

  const LeftAlign = styled.text`
    font-size: ${(props) => props.theme.fontSize.p};
    font-family: ${(props) => props.theme.font.archivo};
    text-align: left;
  `;

  const StyleHeader = styled.text`
    font-size: ${(props) => props.theme.fontSize.subheader};
    font-family: ${(props) => props.theme.font.poppins};
  `;

  const Right = styled.text`
    text-align: left;
    float: left;
    font-size: ${(props) => props.theme.fontSize.xs};
    font-family: ${(props) => props.theme.font.archivo};
    color: ${(props) => props.theme.color.black};
  `;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <StyleHeader>Welcome to Mailable</StyleHeader>
        <LeftAlign className="mb-5">
          Vestibulum efficitur eget mi interdum scelerisque. Duis tempus dolor
          risus, vel mollis mauris maximus non.
        </LeftAlign>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridFirstName">
          <Label>
            <Form.Label>First Name</Form.Label>
          </Label>
          <Form.Control
            type="text"
            onChange={handleInputChange}
            name="first_name"
            required
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridLastName">
          <Label>
            <Form.Label>Last Name</Form.Label>
          </Label>
          <Form.Control
            type="text"
            onChange={handleInputChange}
            name="last_name"
            required
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Label>
            <Form.Label>Email Address</Form.Label>
          </Label>
          <Form.Control
            type="email"
            onChange={handleInputChange}
            name="email"
            required
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className="mb-3">
        <Form.Group as={Col} controlId="formGridPassword">
          <Label>
            <Form.Label>Password</Form.Label>
          </Label>
          <Form.Control
            type="password"
            onChange={handleInputChange}
            name="password"
            required
          />
        </Form.Group>
      </Form.Row>

      <Button className="mb-4" variant="dark" type="submit" block>
        Sign Up
      </Button>
      <Right>
        By clicking ‘Sign Up”, you are creating a Mailable account and you are
        agreeing to Mailable’s Terms of Use and Privacy Policy.
      </Right>
    </Form>
  );
};

RegistrationForm.propTypes = {
  signUp: PropTypes.func.isRequired,
};

export default RegistrationForm;
