import 'react-datetime/css/react-datetime.css';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Spinner, Table } from 'react-bootstrap';
import wretch from 'wretch';
import { size, forEach } from 'lodash';
import { NumberStyle, DateStyle } from '../components/Estimate/styles';

import SimulationNav from '../components/SimulationNav';
import { prepareDayjs } from '../utils';

interface QueryInterface {
  start_at?: string;
  end_at?: string;
}

const HistoricalView = () => {
  const dayjs = prepareDayjs();
  const [selectedStart, setSelectedStart] = useState<Date>(new Date());
  const [selectedEnd, setSelectedEnd] = useState<Date>(new Date());
  const [query, setQuery] = useState<QueryInterface>({
    start_at: dayjs(selectedStart).format('YYYY-MM-DD'),
    end_at: dayjs(selectedEnd).format('YYYY-MM-DD'),
  });
  const [loading, setLoading] = useState(false);
  const [startSelectDate, setSelectDate] = useState(false);
  const [clients, setClients] = useState<any>([]);

  const selectStart = (start: any) => {
    setLoading(true);
    setSelectDate(true);
    setSelectedStart(start);
    const date = dayjs(start).format('YYYY-MM-DD');
    setQuery({ ...query, start_at: date });
  };
  const selectEnd = (end: any) => {
    setLoading(true);
    setSelectedEnd(end);
    const date = dayjs(end).format('YYYY-MM-DD');
    setQuery({ ...query, end_at: date });
  };

  const renderClients = (clients: any) => {
    const result: any = [];
    forEach(clients, (client: any, index) => {
      const { dates } = client;
      result.push(
        <tr>
          <td>{client.client_email}</td>
          <td>
            {size(dates) > 0
              ? Object.entries(dates).map(([key, value]) => (
                  <td key={index}>
                    {[
                      <DateStyle key={index}>
                        {' '}
                        {dayjs(key).format('MMM DD, YYYY')}{' '}
                      </DateStyle>,
                      <NumberStyle key={index}> {value}</NumberStyle>,
                    ]}
                  </td>
                ))
              : null}
          </td>
        </tr>
      );
    });
    return result;
  };

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const formattedQuery = queryString.stringify(query);
    setLoading(true);
    wretch(`${API_URL}/monitoring/sent_per_day?${formattedQuery}`)
      .get()
      .json((response) => {
        setLoading(false);
        setClients(response);
      });
  }, [query]);

  return (
    <>
      <SimulationNav
        selectDate={selectStart}
        selectedDate={selectedStart}
        label="Start Date"
      />
      {startSelectDate && (
        <SimulationNav
          selectDate={selectEnd}
          selectedDate={selectedEnd}
          startSelectDate={selectedStart}
          label="End Date"
        />
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <span>Clients</span>
            </th>
            <th>Dates</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={2}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </td>
            </tr>
          ) : (
            renderClients(clients)
          )}
        </tbody>
      </Table>
    </>
  );
};

export default HistoricalView;
