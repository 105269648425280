import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const NavContainer = styled.div`
  font-family: ${(props) => props.theme.font.archivo};
  padding: 16px;
  display: flex;
  justify-content: center;
`;

const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize.md};
  margin-bottom: 0px;
`;

const NavElement = styled.div`
  > *:first-child {
    color: ${(props) => props.theme.color.grey};
    padding-right: 16px;
  }
`;

interface SimulationNavProps {
  selectDate: (e: any) => void;
  selectedDate: Date;
  startSelectDate?: Date;
  label: String;
}
const SimulationNav = ({
  selectDate,
  selectedDate,
  startSelectDate,
  label,
}: SimulationNavProps) => {
  return (
    <NavContainer>
      <NavElement>
        <Label>{label}</Label>
        <DatePicker
          dateFormat="MMMM do, yyyy"
          selected={selectedDate}
          onChange={(e) => selectDate(e)}
          popperPlacement="bottom-start"
          minDate={startSelectDate ? startSelectDate : undefined}
        />
      </NavElement>
    </NavContainer>
  );
};

export default SimulationNav;
