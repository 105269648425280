import React from 'react';
import { Card, Accordion, Button } from 'react-bootstrap';

import {
  TableCell,
  Round,
  Initiator,
  SubHeader,
  Sender,
  Recipient,
  ThreadID,
} from './styles';
import { abbrTimezone, prepareDayjs, truncate } from '../../utils';
import { ThreadEmail } from '../../api/types';

interface EmailProps {
  item: ThreadEmail;
  round: number;
}

const Email = ({ item, round }: EmailProps) => {
  const dayjs = prepareDayjs();
  const timeZone = dayjs().format('zzz');
  const abbr = abbrTimezone(timeZone);

  return (
    <>
      {item.round === round && (
        <TableCell id={`${round}`}>
          <Round>Round {item.round}</Round>
          {item.thread_number != null && (
            <Initiator>
              <SubHeader>thread no. {item.thread_number}</SubHeader>
            </Initiator>
          )}
          <Sender>{item.sender.email}</Sender>
          <Recipient>{item.recipient.email}</Recipient>
          <span>{item.probability.toFixed(2)}</span>
          <Round>{item.state}</Round>
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <ThreadID>{truncate(item.body, 7)}</ThreadID>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <ThreadID>{item.body}</ThreadID>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Round>
            {item.scheduled_at &&
              dayjs(item.scheduled_at).format('DD MMM YYYY hh:mm:ss a ') + abbr}
          </Round>
          {item.id}
        </TableCell>
      )}
    </>
  );
};

export default Email;
