import React from 'react';

import { Pagination } from 'react-bootstrap';
import styled from 'styled-components';
import { Meta } from '../../api/types';

import generatePaginationElements from './generatePaginationElements';

const StyledPagination = styled(Pagination)`
  margin: 0;
`;
const Container = styled.div`
  font-size: ${(props) => props.theme.fontSize.med};
  display: flex;
  > * {
    margin-left: 8px;
    margin-right: 8px;
  }
  > *:first-child {
    margin-left: 0px;
  }
  > *:last-child {
    margin-right: 0px;
  }
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 8px;
`;

interface WrapperProps {
  page: number;
  meta: Meta;
  loading: boolean;
  setCurrentPage: (page: number) => void;
}

const Wrapper = ({ page, meta, loading, setCurrentPage }: WrapperProps) => {
  if (!meta.total_pages) return <></>;

  const paginationElements = generatePaginationElements(page, meta.total_pages);
  const changePage = (newPage: number) => () => {
    if (newPage === page) return;
    if (loading) return;
    setCurrentPage(newPage);
  };

  return (
    <>
      <Container>
        <StyledPagination>
          <Pagination.First onClick={changePage(1)} disabled={page === 1} />
          <Pagination.Prev
            onClick={changePage(page - 1)}
            disabled={page === 1}
          />
          {paginationElements.map((element: number | string) => {
            return typeof element === 'number' ? (
              <Pagination.Item
                key={element}
                onClick={changePage(element)}
                active={page === element}
              >
                {element}
              </Pagination.Item>
            ) : (
              <Pagination.Ellipsis />
            );
          })}
          <Pagination.Next
            onClick={changePage(page + 1)}
            disabled={page === meta.total_pages}
          />
          <Pagination.Last
            onClick={changePage(meta.total_pages)}
            disabled={page === meta.total_pages}
          />
        </StyledPagination>
      </Container>
    </>
  );
};

export default Wrapper;
