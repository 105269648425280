import React from 'react';
import Client from './Client';
import { abbrTimezone, prepareDayjs } from '../../utils';
import Thread from './Thread';
import { EstimateSnapshot } from '../../api/types';

interface EstimateProps {
  estimate: EstimateSnapshot;
}

const Estimate = ({ estimate }: EstimateProps) => {
  const dayjs = prepareDayjs();
  const timeZone = dayjs().format('zzz');
  const abbr = abbrTimezone(timeZone);
  const { data } = estimate;
  const threads = data.threads || [];
  const clients = data.clients || [];
  return (
    <tr>
      <td>
        {dayjs(estimate.created_at).format('MMM DD, YYYY h:mm:ss a ') + abbr}
        {clients.map(
          (client: any) =>
            client !== null && <Client key={client.id} client={client} />
        )}
      </td>
      {threads.map(
        (thread: any) =>
          thread !== null && <Thread key={thread.id} item={thread} />
      )}
    </tr>
  );
};

export default Estimate;
