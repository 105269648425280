import 'react-datetime/css/react-datetime.css';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Spinner, Table } from 'react-bootstrap';
import wretch from 'wretch';

import Estimate from '../components/Estimate';
import { EstimateSnapshot, EstimateSnapshotWithMeta } from '../api/types';
import SimulationNav from '../components/SimulationNav';
import { prepareDayjs } from '../utils';
import { PaginationWrapper } from '../components/PaginationWrapper';

interface QueryInterface {
  start_at?: string;
  end_at?: string;
  page: number;
  limit?: number;
}

const Simulation = () => {
  const dayjs = prepareDayjs();
  const [estimates, setEstimates] = useState<EstimateSnapshot[]>(
    ([] as unknown) as EstimateSnapshot[]
  );
  const [meta, setMeta] = useState<EstimateSnapshotWithMeta['meta']>(
    ({} as unknown) as EstimateSnapshotWithMeta['meta']
  );
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [query, setQuery] = useState<QueryInterface>({
    start_at: dayjs(selectedDate).format('YYYY-MM-DD'),
    page: 1,
    limit: 5,
  });
  const [loading, setLoading] = useState(false);

  const setCurrentPage = (page: number) => {
    setLoading(true);
    setQuery({ ...query, page: page });
  };

  const selectDate = (e: any) => {
    setLoading(true);
    setSelectedDate(e);
    const date = dayjs(e).format('YYYY-MM-DD');
    setQuery({ ...query, start_at: date });
  };

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const formattedQuery = queryString.stringify(query);
    setLoading(true);
    wretch(`${API_URL}/monitoring/estimates?${formattedQuery}`)
      .get()
      .json((json) => {
        const response = json as EstimateSnapshotWithMeta;
        setLoading(false);
        setMeta(response.meta);
        setEstimates(response.collection);
      });
  }, [query]);

  return (
    <>
      <SimulationNav
        selectDate={selectDate}
        selectedDate={selectedDate}
        label="Start Date"
      />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <span>Time</span>
            </th>
            <th>ROUNDS</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <td colSpan={2}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </td>
          ) : (
            estimates.map((estimate: any) => (
              <Estimate key={estimate.id} estimate={estimate} />
            ))
          )}
        </tbody>
      </Table>
      <PaginationWrapper
        {...{ page: query.page, meta, loading, setCurrentPage }}
      />
    </>
  );
};

export default Simulation;
