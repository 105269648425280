import React from 'react';
import { abbrTimezone, prepareDayjs } from '../../utils';
import Thread from './Thread';
import { ClientEstimateSnapshot } from '../../api/types';

interface EstimateProps {
  estimate: ClientEstimateSnapshot;
}

const ClientIndex = ({ estimate }: EstimateProps) => {
  const dayjs = prepareDayjs();
  const abbr = abbrTimezone(dayjs().format('zzz'));
  const threads = estimate.threads || [];
  return (
    <tr>
      <td>
        {dayjs(estimate.created_at).format('MMM DD, YYYY h:mm:ss a ') + abbr}
      </td>
      {threads.map(
        (thread: any) =>
          thread !== null && <Thread key={thread.id} item={thread} />
      )}
    </tr>
  );
};

export default ClientIndex;
