import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';

export const truncate = (emailBody: any, words: any) => {
  return emailBody.split(' ').splice(0, words).join(' ');
};

export const abbrTimezone = (timeZone: any) => {
  return timeZone
    .split(' ')
    .map((item: any) => {
      return item[0];
    })
    .join('');
};

export const prepareDayjs = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(advancedFormat);
  dayjs.extend(localeData);
  dayjs.weekdays();
  return dayjs;
};
