// TODO: Refactor the API function. Remove the eslint-disable

const headers = {
  'Content-Type': 'application/json;charset=utf-8',
  Authorization: '',
};

const API_URL = process.env.REACT_APP_API_URL;

const call = (path: string, method: string, data: null, queryObj?: any) => {
  return fetch(`${API_URL}/${path}${getQueryString(queryObj)}`, {
    method,
    mode: 'cors',
    body: data && JSON.stringify(data),
    headers,
  }).then((response) => {
    if (!response.ok) {
      return Promise.reject(response);
    }

    return response;
  });
};

const getQueryString = (queryObj: any) => {
  if (queryObj) {
    const entries = Object.entries(queryObj);

    if (entries.length) {
      return `?${entries
        .map(([key, value]) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value + '')}`;
        })
        .join('&')}`;
    }
  }

  return '';
};

export const get = (url: string, queryObj?: any) =>
  call(url, 'get', null, queryObj);

export const post = (url: string, data: any, queryObj?: any) =>
  call(url, 'post', data, queryObj);

export const put = (url: string, data: any, queryObj?: any) =>
  call(url, 'put', data, queryObj);

export const del = (url: string) => call(url, 'delete', null);

export const setAuthToken = (token: string) => {
  headers['Authorization'] = `Token ${token}`;
};

// export const removeAuthToken = () => {
//   delete headers['Authorization'];
// };
